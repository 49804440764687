import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.store('burgerMenuShow', false);

Alpine.data('topmenu', () => ({
  nav: null,
  progressTarget: false,
  progressStyle: { display: 'none', transform: 'scaleX(0)' },
  tTop: 0,
  tHeight: 0,
  tBottom: 0,
  timer: null,
  time: 0,
  delay: 200,
  transparentAboveTarget: null,

  carouselIndex: 0,
  href: null,

  topMenu: null,
  minimiseTopMenu: false,
  startMinimised: null,
  oldScroll: 0,
  minimiseTime: {
    current: 0,
    delay: 100
  },

  init() {
    this.nav = this.$refs.nav ? this.$refs.nav : false;

    this.topMenu = this.$el;

    
    this.startMinimised = document.querySelector('*[data-header-minimise="true"]');

    if (this.startMinimised) {
      this.minimiseTopMenu = true;
    }

    this.progressTarget = document.querySelector('*[data-reading-progress-target="true"]');

    if (this.progressTarget) {

      this.setStartValues();
      this.time = Date.now();
      window.addEventListener('scroll', () => {
        this.progressStyle.display = 'block';
        const now = Date.now();
        if (this.time + this.delay < now) {
          this.updateProgress();
          if (this.timer) clearTimeout(this.timer);
          this.timer = setTimeout(this.updateProgress.bind(this), this.delay);
          this.time = now;
        }
        this.updateProgress();
      }, { passive: true });
    }

    this.minimiseTime.current = Date.now();
    window.addEventListener("scroll", () => {
      const now = Date.now();
      if (this.minimiseTime.current + this.minimiseTime.delay < now) {
        if (this.oldScroll < window.scrollY) {
          this.minimiseTopMenu = true;
        } else {
          this.minimiseTopMenu = false;
        }

        this.oldScroll = window.scrollY;
        this.minimiseTime.current = now;
      } else {
        if(window.scrollY === 0) {
          this.minimiseTopMenu = false;
        }
      }
    });

    

  },

  setStartValues() {
    if (!this.progressTarget) return;
    this.tTop = this.progressTarget.offsetTop;
    this.offsetHeight = this.progressTarget.offsetHeight;
    this.tBottom = this.tTop + this.progressTarget.offsetHeight - window.innerHeight;
    this.tHeight = this.tBottom - this.tTop;
    this.updateProgress(this.progressTarget.offsetTop + this.progressTarget.offsetHeight);
  },

  updateProgress() {
    const scroll = window.scrollY;
    if (scroll >= this.tTop && scroll <= this.tBottom + 100) {
      let percentage = (scroll - this.tTop) / this.tHeight;

      this.progressStyle.transform = `scaleX(${percentage})`;

      this.currentPercentage = percentage;
    } else {
      this.progressStyle.transform = 'scaleX(1)';
    }
  },

  toggleBurgerMenu() {
    this.$store.burgerMenuShow = !this.$store.burgerMenuShow;
    this.oldScroll = window.scrollY;
    this.minimiseTopMenu = false;
  },

  fullscreenBurgerMenu() {
    return window.innerHeight < this.$refs.burger_menu.clientHeight;
  }

}))
