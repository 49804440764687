import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('dynamic_font', (setConfig) => ({
  config: setConfig ?? { image_top: false },
  fontContainer: {
    height: 0,
    width: 0,
  },
  fontSize: 0,
  toobig: false,
  init() {
    //this.$refs.titleWrapper.style.opacity = 0

    let wrapperElement = this.$el;

    let fullHeight = wrapperElement.clientHeight;
    let imageHeight = wrapperElement.firstElementChild.firstElementChild.clientHeight;

    this.fontContainer.height = this.config.image_top ? fullHeight - imageHeight : fullHeight;
    this.fontContainer.width = wrapperElement.clientWidth;

    console.log(this.$refs.titleWrapper.clientHeight, this.$refs.titleWrapper.innerText, this.$refs.titleWrapper.innerText.length, this.fontContainer.height)


    if ( this.$refs.titleWrapper.clientHeight < this.fontContainer.height ) {
      console.log(this.fontContainer.height, this.$refs.titleWrapper.clientHeight)
    //  this.$refs.titleWrapper.style.fontSize =`${this.fontContainer.height - this.$refs.titleWrapper.clientHeight}px`;
      
    }

    //this.iteration();
    /*
        const aE = document.getElementById('quote');
        const bE = document.getElementById('quoted');
        const oE = document.getElementById('bubble-outer');
        let svgHeight = 0;
        let fontSize = 0;
        let toobig = false;
        let qi = null;
        const init = function (quoteIndex = null) {
          aE.style.opacity = 0;
          bE.style.opacity = 0;
          if (quoteIndex !== null) {
            qi = quoteIndex;
            aE.innerHTML = quotes[quoteIndex].a;
            bE.innerHTML = quotes[quoteIndex].b;
          }
          toobig = false;
          svgHeight = document.getElementById('bubble-svg').clientHeight;
          bE.style.fontSize = `${svgHeight * 0.075}px`;
          bE.style.bottom = `-${svgHeight * 0.1}px`;
          oE.style.marginBottom = `${bE.innerHTML.length > 0 ? svgHeight * 0.1 + 20 : 20}px`;
          fontSize = 10;
          aE.style.fontSize = `${fontSize}px`;
          iteration();
        };
        
        window.setQuote = (delta) => {
          init((quotes.length + (qi + delta)) % quotes.length);
        };
        init(Math.floor(Math.random() * quotes.length));
        window.addEventListener('resize', () => {
          if (document.getElementById('bubble-svg').clientHeight !== svgHeight) {
            init();
          }
        });
        oE.addEventListener('swiped-left', setQuote.bind(this, -1));
        oE.addEventListener('swiped-right', setQuote.bind(this, 1));
      */


      
  },
  iteration() {
    if (!this.toobig && (this.$refs.titleWrapper.clientHeight < this.fontContainer.height)) {
      this.fontSize += 5;
      this.$refs.titleWrapper.style.fontSize = `${this.fontSize}px`;
      requestAnimationFrame(this.iteration.bind(this));
    } else if ((this.$refs.titleWrapper.clientHeight > this.fontContainer.height)) {
      this.toobig = true;
      this.fontSize--;
      this.$refs.titleWrapper.style.fontSize = `${this.fontSize}px`;
      requestAnimationFrame(this.iteration.bind(this));
    } else {
      //aE.style.opacity = 1;
      //bE.style.opacity = 1;
      //this.$refs.titleWrapper.style.opacity = 1
    }
  }
}));